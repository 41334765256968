//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaHeader from '@/components/SanghaHeader.vue';
import { ApiService, Auth } from '@/services/api.service';
import { getEmail } from '@/services/token.service';

export default {
  name: 'RecoveryLinkLogin',
  data() {
    return {
      state: 'default',
      email: null,
      code: null,
      errors: {
        email: false,
      },
    };
  },
  components: {
    SanghaHeader,
  },
  methods: {},
  computed: {
    getEmail() {
      return this.$store.state.auth.authorizedUser.email;
    },
    getToken() {
      return this.$store.state.auth.authorizedUser.token;
    },
  },
  mounted() {
    try {
      if (this.$route.query && this.$route.query.code) {
        ApiService.setHeader();
        let email = getEmail();
        if (!email) throw new Error('Email is missing');

        Auth.finishRecovery({
          email: email,
          email_verification_code: this.$route.query.code,
        }).then(
          (response) => {
            let responseData = response.data.data.verifyOnboarding;
            if (
              responseData &&
              responseData.success &&
              responseData.onboarding &&
              responseData.onboarding.existingUser &&
              responseData.onboarding.existingUser.id
            ) {
              this.$store.commit(
                'login',
                responseData.onboarding.existingUser.id
              );

              // login action using JWT
              Auth.login({
                email: email,
                password: responseData.passcode,
              }).then((loginResponse) => {
                let loginResponseData = loginResponse.data.data.sanghaJwt;

                if (
                  !loginResponseData.success ||
                  loginResponseData.jwtSangha.mantra !=
                    'OM A HUM VAJRA GURU PADMA SIDDHI HUM'
                ) {
                  this.$store.commit('removeApiToken');
                  this.errors.email = true;
                } else {
                  this.$store.commit('setJWTToken', {
                    token: loginResponseData.jwtSangha.token,
                    refresh: loginResponseData.jwtSangha.refreshToken,
                  });

                  /// decide where to go depending on params:
                  if (this.$route.query && this.$route.query.returnTo) {
                    setTimeout(() => {
                      this.$router.replace(this.$route.query.returnTo);
                    }, 1000);
                  } else {
                    setTimeout(() => {
                      this.$router.replace({ name: 'index' });
                    }, 1000);
                  }
                }
              });
            } else {
              this.errors.email = true;
            }
          },
          () => (this.errors.email = true)
        );
      } else {
        throw new Error('No credentials');
      }
    } catch (e) {
      this.errors.email = true;
    }
  },
};
